import {Injectable} from '@angular/core';
import {forkJoin, of, Subject} from 'rxjs';
import {FileServiceService} from '../../../../../file-service/src/lib/file-service.service';
import {AnyFileManagerService} from './file-manager';
import {FileModel} from '../../../../../file-service/src/lib/models/file.model';
import {MAX_PAGING_LIMIT} from '../../../shared/constants';
import {NotifierService} from '../../../shared/services/notifier.service';
import {TreeNode} from 'primeng/api';
import {catchError} from 'rxjs/operators';
import {TableLazyLoadEvent} from 'primeng/table/table.interface';
import {FILE_DELETED_FROM_SERVER} from '../../../../locale/multilingual-strings-constants';

@Injectable()
export class HttpFileManagerService implements AnyFileManagerService {
  public directories$: Subject<any> = new Subject();
  public files$: Subject<any> = new Subject();
  public files: any[] = []; // todo remove?
  public totalRecords: number;
  public items: any[] = [];
  public loadedFilesIds: string[] = [];
  public fetchingDone$: Subject<void> = new Subject();
  public changeDetection$: Subject<void> = new Subject();
  public machineId: string;
  private directoryCache: Map<string, TreeNode[]> = new Map();

  public constructor(
    private fileService: FileServiceService,
    private notifierService: NotifierService,
  ) {
    console.log('HttpFileManagerService constructor');
  }

  public getFiles(): FileModel[] {
    console.log('todo http-files');
    return [];
  }

  public onInit(machineId: string, directoryId: string) {
    this.machineId = machineId;
    this.fileService.getDirectory(this.machineId, 1, MAX_PAGING_LIMIT, directoryId ? directoryId : '')
      .subscribe({
        next: dirsRes => {
          if (dirsRes.succeed) {
            if (!dirsRes.value.entities.length) {
              this.directories$.next([]);
            } else {
              dirsRes.value.entities.forEach(directoryId => {
                this.fetchDirectoryDetails(directoryId);
              })
            }
          } else this.notifierService.handleErrors(dirsRes.errors);
        },
        error: err => this.notifierService.handleRequestError(err),
      });
  }

  private fetchDirectoryDetails(directoryId: any) {
    this.fileService.getDirectoryById(directoryId, this.machineId)
      .subscribe({
        next: dirRes => {
          if (dirRes.succeed) {
            this.directories$.next(this.buildDirectoriesNode([dirRes.value]));
          } else {
            this.notifierService.handleErrors(dirRes.errors);
          }
        },
        error: err => this.notifierService.handleRequestError(err),
      });
  }

  private buildDirectoriesNode(value: any[]): TreeNode[] {
    const nodes: TreeNode[] = [];

    value.forEach(directory => {
      const directoryNode: TreeNode = {
        label: directory.name,
        data: directory.directoryId,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        leaf: false,
      };
      nodes.push(directoryNode);
    });
    return nodes;
  }

  public openDirectory(node: any, loadChildren = true): void {
    console.log(loadChildren);
    if (!node.children) {
      node.expanded = true;
      this.fetchChildDirectories(node);
    }
    this.loadedFilesIds = [];
    this.getDirectoryFiles(node.data);
  }

  private fetchChildDirectories(node: TreeNode): void {
    const directoryId = node.data;

    if (this.directoryCache.has(directoryId)) {
      node.children = this.directoryCache.get(directoryId) || [];
      node.expanded = true;
    } else {
      this.fileService.getDirectory(this.machineId, 1, MAX_PAGING_LIMIT, directoryId ? directoryId : '')
        .subscribe({
          next: dirsRes => {
            if (dirsRes.succeed) {
              const childDirectories = dirsRes.value;
              if (childDirectories && childDirectories.entities.length > 0) {
                const directoryObservables = childDirectories.entities.map((directory: any) =>
                  this.fileService.getDirectoryById(directory, this.machineId).pipe(
                    catchError(error => {
                      this.notifierService.handleRequestError(error);
                      return of(null);
                    })
                  )
                );

                forkJoin(directoryObservables).subscribe({
                  next: (directories: any) => {
                    const childNodes = directories
                      .filter((dir: any) => dir !== null)
                      .map((dir: any) => this.buildDirectoriesNode([dir.value]));
                    node.children = [];
                    childNodes.forEach((children: TreeNode[]) => {
                      children.forEach(child => node.children?.push(child))
                    });
                    node.leaf = false;
                    node.expanded = true;
                    this.fetchingDone$.next();
                    this.directoryCache.set(directoryId, node.children || []);
                  },
                  error: err => this.notifierService.handleRequestError(err)
                });
              } else {
                node.children = [];
              }
            } else {
              this.notifierService.handleErrors(dirsRes.errors);
            }
          },
          error: err => this.notifierService.handleRequestError(err),
        });
    }
  }

  getDirectoryFiles(selectedDirectoryId: string, event?: TableLazyLoadEvent, reset = true): void {
    this.fileService.getTerminalFiles(this.machineId, 1, 200, selectedDirectoryId as string).subscribe({ // todo 200
      next: (res) => {
        if (res.succeed) {
          const existingFiles = this.files.filter((t: any) => res.value.entities.includes(t.id));
          const newItems = res.value.entities.filter(item => !existingFiles.some((t: any) => t.id === item));
          this.files = [...existingFiles, ...newItems.map(id => ({id: id, isFile: true,}))];
          this.files$.next(this.files);
          this.totalRecords = res.value.totalCount;
          this.items = res.value.entities;
          const batchSize = 30;

          for (let i = 0; i < this.items.length; i++) {
            if (!this.loadedFilesIds.includes(this.items[i])) {
              const terminalId = this.items[i];
              this.loadNextFile(terminalId);
            }
          }
        } else {
          this.notifierService.handleErrors(res.errors);
        }
      },
    });
  }

  private loadNextFile(fileId: string) {
    this.loadedFilesIds.push(fileId);
    this.fileService.getFile(fileId, this.machineId)
      .subscribe((res: any) => {
        const file = this.files.find((t: any) => t.id === fileId);
        if (file) {
          // console.log(file);
          file.name = {fieldLoaded: true, value: res.value.name};
          file.directoryId = {fieldLoaded: true, value: res.value.directoryId};
          file.fileExtensionId = {fieldLoaded: true, value: res.value.fileExtensionId};
          file.status = {fieldLoaded: true, value: res.value.status};
          file.isDeleted = {fieldLoaded: true, value: res.value.isDeleted};
          file.fullPath = {fieldLoaded: true, value: res.value.fullPath};
          file.bytesTransferred = {fieldLoaded: true, value: res.value.bytesTransferred ? Math.round(((+res.value.bytesTransferred!)*100)/res.value.fileSize!) : null};
          file.dateModified = {
            fieldLoaded: true,
            value: res.value.dateModified
          };
          file.fileSize = {fieldLoaded: true, value: res.value.fileSize};
          this.files = [...this.files]; // todo ngDoCheck?
          this.files$.next(this.files);
        }
      });
  }

  public deleteFile(file: any) {
    this.fileService.deleteFile(file.id).subscribe({
      next: res => {
        if (res.succeed) {
          this.notifierService.handleSuccessRequest(FILE_DELETED_FROM_SERVER);
        }
      },
      error: err => {
        this.notifierService.handleRequestError(err);
      },
    });
  }

  public deleteDirectory(dirPath: string) {
    console.log('to be deleted: ', dirPath);
  }

  public onDestroy() {
    console.log('todo nothing?');
  }

  private loadFiles(start: number, end: number): void {
    for (let i = start; i < end; i++) {
      if (i < this.items.length && !this.loadedFilesIds.includes(this.items[i])) {
        const fileId = this.items[i];
        this.loadNextFile(fileId);
      }
    }
  }

  onLoad(file: any): void {
    const start = file.first;
    const end = file.last;
    this.loadFiles(start, end);
  }

  onLoadThumbnails(event: any): void {
    const start = event.first;
    const end = event.last;
    this.loadFiles(start, end);
  }

  public navigateToRoot() {
    console.log('todo');
  }


}
