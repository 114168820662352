import {Inject, Injectable, InjectionToken} from '@angular/core';
import {UrlRedirectionModel} from './models/url-redirection.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {first, Observable} from 'rxjs';
import {NewResponse, Response} from './models/response';
import {UrlRedirectionLogModel} from './models/url-redirection-log.model';
import {UrlRedirectionRuleModel} from './models/url-redirection-rule.model';
import {URL_REDIRECTION_API_URL, URL_REDIRECTION_URL} from '../../../src/app/shared/constants';
import {RedirectionStatisticModel} from './models/redirection-statistic.model';

export const API_URL = new InjectionToken<string>('apiUrl');


@Injectable()
export class UrlRedirectionService {
  private static readonly urlRedirectionApiUrl = `${URL_REDIRECTION_API_URL}/url-redirection`;
  private readonly redirectionGroups: string;
  private readonly urlRedirection: string;
  private readonly urlRedirectionLog: string;
  private readonly urlRedirectionRule: string;
  private readonly redirectionStatistics: string;
  private readonly redirectionHealth: string;
  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly headersSkippingToken: HttpHeaders;
  private readonly optionsSkippingToken: object;
  private readonly optionsSkippingInterceptor: object;

  public constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingToken = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipToken': 'true',
      'skipInterceptor': 'true',
    });
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingToken = {
      headers: this.headersSkippingToken,
      withCredentials: true
    };

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };

    this.redirectionGroups = `${UrlRedirectionService.urlRedirectionApiUrl}/redirectionGroups`;
    this.redirectionHealth = `${UrlRedirectionService.urlRedirectionApiUrl}/healthz`;
    this.redirectionStatistics = `${UrlRedirectionService.urlRedirectionApiUrl}/statistics`;
    this.urlRedirection = `${UrlRedirectionService.urlRedirectionApiUrl}/Redirections`;
    this.urlRedirectionLog = `${UrlRedirectionService.urlRedirectionApiUrl}/RedirectionLogs`;
    this.urlRedirectionRule = `${UrlRedirectionService.urlRedirectionApiUrl}/RedirectionRules`;
  }


  // RedirectionGroups
  public getAllUrlRedirections(groupId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.redirectionGroups}/${groupId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUrlRedirectionGroup(groupId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.redirectionGroups}/${groupId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Redirection
  public getUrlRedirectionById(redirectionId: string): Observable<Response<UrlRedirectionModel>> {
    return this.http.get<Response<UrlRedirectionModel>>(`${this.urlRedirection}/${redirectionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createUrlRedirection(groupId: string, body: UrlRedirectionModel): Observable<NewResponse<string>> {
    return this.http.post<NewResponse<string>>(`${this.urlRedirection}/${groupId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateUrlRedirection(redirectionId: string, body: UrlRedirectionModel): Observable<NewResponse<void>> {
    return this.http.put<NewResponse<void>>(`${this.urlRedirection}/${redirectionId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUrlRedirection(redirectionId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlRedirection}/${redirectionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public handleUrlRedirection(redirectionId: string): Observable<void> {
    return this.http.get<void>(`${this.urlRedirection}/redirect/${redirectionId}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Redirection log
  public getAllUrlRedirectionLogs(redirectionId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlRedirectionLog}/${redirectionId}/logs?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getUrlRedirectionLog(redirectionLogId: string): Observable<Response<UrlRedirectionLogModel>> {
    return this.http.get<Response<UrlRedirectionLogModel>>(`${this.urlRedirectionLog}/${redirectionLogId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRedirectionLog(logId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlRedirectionLog}/${logId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteRedirectionLogs(redirectionId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlRedirectionLog}/${redirectionId}/logs/clear`, this.optionsSkippingInterceptor).pipe(first());
  }


  // Redirection rule
  public getUrlRedirectionRule(redirectionId: string, page: number, size: number): Observable<NewResponse<UrlRedirectionRuleModel[]>> {
    return this.http.get<NewResponse<UrlRedirectionRuleModel[]>>(`${this.urlRedirectionRule}/${redirectionId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public createUrlRedirectionRule(body: UrlRedirectionRuleModel, redirectionId: string): Observable<NewResponse<void>> {
    return this.http.post<NewResponse<void>>(`${this.urlRedirectionRule}/${redirectionId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateUrlRedirectionRule(redirectionRuleId: string, body: UrlRedirectionRuleModel): Observable<NewResponse<void>> {
    return this.http.put<NewResponse<void>>(`${this.urlRedirectionRule}/${redirectionRuleId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteUrlRedirectionRule(redirectionRuleId: string): Observable<NewResponse<void>> {
    return this.http.delete<NewResponse<void>>(`${this.urlRedirectionRule}/${redirectionRuleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Statistics

  public getRedirectionStatistics(redirectionId: string): Observable<Response<RedirectionStatisticModel>> {
    return this.http.get<Response<RedirectionStatisticModel>>(`${this.redirectionStatistics}/redirections/${redirectionId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getRuleStatistics(ruleId: string): Observable<NewResponse<RedirectionStatisticModel>> {
    return this.http.get<NewResponse<RedirectionStatisticModel>>(`${this.redirectionStatistics}/rules/${ruleId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.redirectionHealth}`, this.optionsSkippingInterceptor).pipe(first());
  }
}
