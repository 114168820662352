import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MenuItem, TreeNode} from 'primeng/api';
import {IconSizeModel} from '../../../../shared/model/icon-size.model';
import {FileEventService} from '../file-event.service';
import {DEFAULT_TABLE_LIMIT, DEFAULT_TABLE_ROWS_OPTIONS} from '../../../../shared/constants';
import {ReplaySubject, Subject} from 'rxjs';
import {TableFieldModel} from '../../../../shared/model/table-field.model';
import {FieldSortTypesEnum} from '../../../../shared/field-sort-types.enum';
import {FieldTypesEnum} from '../../../../shared/field-types.enum';
import {TableLazyLoadEvent, TableRowSelectEvent} from 'primeng/table/table.interface';
import {TableComponent} from '../../../../shared/components/table/table.component';
import {CheckboxChangeEvent} from 'primeng/checkbox';
import {UtilService} from '../../../../shared/services/util.service';
import {SyncStatusEnum} from '../../../../../../file-service/src/lib/models/sync-status.enum';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DeletePopup} from '../../../../shared/components/delete-popup/delete-popup.component';
import {ShellWebSocketService} from '../../../../shared/services/shell-web-socket.service';
import {DataConversionService} from '../../../../shared/services/data-conversion.service';
import {ToastService} from '../../../../shared/services/toast.service';
import {FileServiceService} from '../../../../../../file-service/src/lib/file-service.service';
import {NotifierService} from '../../../../shared/services/notifier.service';

@Component({
  selector: 'app-file-services-table',
  templateUrl: './file-services-table.component.html',
  styleUrls: ['./file-services-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileServicesTableComponent implements OnInit, OnChanges {
  @Input() public isOnline = false;
  @Input() public selectedIconSize: IconSizeModel;
  @Input() public machineId: string;
  @Input() public machineName: string;
  @Input() public currentPath: string;
  @Input() public selectedFiles: TreeNode[];
  @Input() public files: any[] = [];
  @Input() public filesShownInExplorer: TreeNode[] = [];
  @Output() public onRowDataChange$: EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }> = new EventEmitter<{ row: TableRowSelectEvent; event: CheckboxChangeEvent, data: any[] }>();
  @Output() public filesLazyLoad: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();
  @Output() public fileLoad: EventEmitter<any> = new EventEmitter<any>();
  public rowsLimit = DEFAULT_TABLE_LIMIT;
  public FILES_LIMIT: number;
  public rowsPerPageOptions = DEFAULT_TABLE_ROWS_OPTIONS;
  public switch$: Subject<void> = new Subject();
  public destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public virtualScrollOptions: any;
  private allRowsCount: number;
  @Input() public totalRecords: number;
  public first = 0;
  @Input() public loading = false;
  @Input() public storedLimit: string;
  @Input() currentNode: any;
  @Output() public selectedNodeInPath: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('filesTableComponent') public filesTableComponent: TableComponent;
  @Input() public isRegisterEditor: boolean;
  @Input() selectedDirectory: any;
  @Input() isPreviewModeOpened: boolean;
  @Output() public deleteRegistryEntry: EventEmitter<any> = new EventEmitter<any>();
  public selectedFileIds: string[] = [];

  public ref: DynamicDialogRef | undefined;

  public selectedFile: any;
  public fileIsSelected: boolean;
  public contextMenuItems: MenuItem[] = [
    {
      label: 'Download', icon: 'pi pi-fw pi-download'
    },
    {
      label: 'Delete', icon: 'pi pi-fw pi-trash',
    },
  ];
  public contextMenuItemsForRegEdit: MenuItem[] =[      {
    label: 'Delete', icon: 'pi pi-fw pi-trash',
  }, ];

  public fileFields: TableFieldModel[] = [];
  public additionalFieldsForFiles: TableFieldModel[] = []

  public constructor(
    private fileEventService: FileEventService,
    private utilService: UtilService,
    private dialogService: DialogService,
    private webSocketService: ShellWebSocketService,
    private dataConversionService: DataConversionService,
    private toastService: ToastService,
    private notifierService: NotifierService,
    private fileService: FileServiceService,
  ) {}

  public ngOnInit() {
    this.additionalFieldsForFiles = this.isRegisterEditor ? [] : [{
      name: 'fieldIsSelected',
      displayName: '',
      isCopy: false,
      globalFilter: false,
      searchable: false,
      sortable: false,
      type: FieldTypesEnum.boolean,
    }];
    this.fileFields = this.isRegisterEditor ? [
      {
        name: 'name',
        displayName: 'Name',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        sortType: FieldSortTypesEnum.string,
        searchable: true,
        type: FieldTypesEnum.object
      }, {
        name: 'type',
        displayName: 'Type',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        sortType: FieldSortTypesEnum.string,
        searchable: true,
      }, {
        name: 'data',
        displayName: 'Data',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        sortType: FieldSortTypesEnum.string,
        searchable: true,
      }] : [
      {
        name: 'name',
        displayName: 'Name',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        sortType: FieldSortTypesEnum.string,
        searchable: true,
        type: FieldTypesEnum.object,
        isFileName: true,
      },
      {
        name: 'fileSize',
        displayName: 'File Size',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        searchable: false,
        type: FieldTypesEnum.object
      },
      {
        name: 'dateModified',
        displayName: 'Date Modified',
        isCopy: false,
        globalFilter: false,
        sortable: true,
        searchable: false,
        type: FieldTypesEnum.object
      },
      {
        name: 'status',
        displayName: 'Status',
        searchable: true,
        type: FieldTypesEnum.object,
        isStatusField: true,
        progress: true,
        progressFieldName: 'bytesTransferred',
      },
    ];
    this.fileEventService.tableChanges.subscribe(() => {
      console.log('table changes');
      this.filesTableComponent.cdr.detectChanges();
    });
    this.fileEventService.onSelectNodeInPath$.subscribe((node: any) => this.selectedNodeInPath.emit(node))
  }

  public emitFilesLazyLoad(event: any) {
    this.filesLazyLoad.emit(event);
  }

  public emitFileLoad(file: any) {
    this.fileLoad.emit(file);
  }

  public navigateToRoot() {
    this.fileEventService.navigateToRootDirectory();
  }

  public previewInSplitterChange(previewInSplitter: boolean) {
    this.isPreviewModeOpened = previewInSplitter;
    this.fileEventService.triggerPreviewFileViewEvent();
  }


  public nodeSelect(node: any): void {
    node.selectable = true;
    this.fileEventService.nodeSelect(node, false);
  }

  public onFileView(node: any): void {
    if (node.isFile) {
      this.fileEventService.triggerFileViewEvent(node);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['filesShownInExplorer']) {
      this.filesShownInExplorer = this.sortFilesBySelectability(this.filesShownInExplorer);
    }
  }

  private sortFilesBySelectability(files: TreeNode[]): TreeNode[] {
    return files.sort((a, b) => {
      const selectableA = a.selectable || false;
      const selectableB = b.selectable || false;

      if (selectableA && !selectableB) {
        return 1;
      } else if (!selectableA && selectableB) {
        return -1;
      } else {
        return a.label?.localeCompare(b.label || '') || 0;
      }
    });
  }
  private openDeleteFilePopup(file: any, directory: any) {
    this.ref = this.dialogService.open(DeletePopup, {
      header: 'Are you sure you want to delete this file?',
      width: 'fit-content',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      data: {

        data: this.dataConversionService.convertData(
          [file],
          {
            name: 'Name',
          }
        ),
      },
    });

    this.ref.onClose.subscribe((result?: { delete: boolean }) => {
      if (result?.delete) {
        const path = directory.key;
        const fileName = file.name.value;
        const command = `reg delete "${path}" /v ${fileName} /f`;
        this.webSocketService.sendMessage(command);
        this.deleteRegistryEntry.emit(directory);
      }
    });
  }


  public initCmItems() {
    if (this.isRegisterEditor) {
      this.contextMenuItemsForRegEdit=[{
        label: 'Delete',
        icon: 'pi pi-fw pi-trash',
        command: () => {
          this.openDeleteFilePopup(this.selectedFile,this.selectedDirectory)
        }
      }];
    } else {
      const fileStatus = (this.selectedFile as any).status.value;
      const fileIsSynced = fileStatus === SyncStatusEnum.synced;
      const fileIsFound = fileStatus !== SyncStatusEnum.notFound && fileStatus !== SyncStatusEnum.error && fileStatus !== SyncStatusEnum.skipped;
      const copyMenuOptions = [];
      if (!this.isOnline || (!(this.selectedFile as any).isDirectory && fileIsFound)) {
        copyMenuOptions.push({
          label: 'Copy ID',
          command: () => {
            this.utilService.copyText((this.selectedFile as any).id, `${(this.selectedFile as any)
              .isDirectory ? 'Directory' : 'File'} ID copied.`);
          }
        });
      }
      if (this.isOnline) {
        copyMenuOptions.push({
          label: 'Copy Path',
          command: () => {
            this.utilService.copyText((this.selectedFile as any).data, `${(this.selectedFile as any)
              .isDirectory ? 'Directory' : 'File'} path copied.`);
          }
        });
      }
      const copyMenuItem = {
        label: 'Copy',
        icon: 'pi pi-fw pi-copy',
        items: copyMenuOptions,
      };
      const directoryMenuItems = [
        {
          label: 'Open', icon: 'pi pi-fw pi-arrow-down-left', command: () => {
            this.fileEventService.openFolder(this.selectedFile.data);
          }
        },
        // {
        //   label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => {
        //     this.fileEventService.deleteFile(this.selectedFile);
        //   }
        // },
        copyMenuItem,
      ];
      const fileMenuItems = [];
      if (!this.selectedFile['userIsSelected'] && (this.selectedFile.status?.value === SyncStatusEnum.synced)) {
        fileMenuItems.push({
          label: 'Select', icon: 'pi pi-check', command: () => {
            this.fileIsSelected = !this.fileIsSelected;
          }
        })
      }
      fileMenuItems.push(
        {
        label: 'Local',
        icon: 'pi pi-fw pi-desktop',
        items: fileIsSynced ? [
          {
            label: 'View', icon: 'pi pi-fw pi-eye', command: () => {
              this.onFileView(this.selectedFile);
            }
          },
          {
            label: this.selectedFileIds.length ? 'Download selected' : 'Download', icon: 'pi pi-fw pi-download', command: () => {
              if (this.selectedFileIds.length) {
                this.downloadSelectedFiles();
              } else {
                this.nodeSelect(this.selectedFile);
              }
            }
          },
          {
            label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => {
              this.fileEventService.deleteFile(this.selectedFile);
            }
          },
        ] : [
          {
            label: 'Transfer', icon: 'pi pi-fw pi-sync', command: () => {
              this.fileEventService.syncFile(this.selectedFile);
            }
          },
        ],
      });
      if (this.isOnline) {
        fileMenuItems.push({
          label: 'Remote',
          icon: 'pi pi-fw pi-desktop',
          items: [
            {
              label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => {
                this.fileEventService.deleteFile(this.selectedFile, false);
              }
            },
          ],
        });
      }
      fileMenuItems.push(copyMenuItem);
      this.contextMenuItems = (this.selectedFile as any).isDirectory ? directoryMenuItems : fileMenuItems;
    }
  }

  public onRowClick(row: any) {
    if (row.isDirectory) {
      this.fileEventService.openFolder(row.data);
    } else {
      const fileStatus = row?.status?.value;
      const fileIsSynced = fileStatus === SyncStatusEnum.synced;
      if (fileIsSynced) {
        this.onFileView(row);
      }
    }
  }

  public onRowDataChange(event: { row: any; event: CheckboxChangeEvent; data: any[] }) {
    this.changeSelectedFiles(event);
    this.onRowDataChange$.emit(event);
  }

  public changeSelectedFiles(file: { row: any; event: CheckboxChangeEvent, data: any[] }) {
    const checked = file.event.checked;
    file.row.fileIsSelected = checked;
    if (checked && !this.selectedFileIds.includes(file.row.id)) {
      this.selectedFileIds.push(file.row.id);
    }
    if (!checked && this.selectedFileIds.includes(file.row.id)) {
      this.selectedFileIds.splice(this.selectedFileIds.indexOf(file.row.id), 1);
    }
  }

  public downloadSelectedFiles() {
    if (!this.selectedFileIds || !this.selectedFileIds.length) {
      this.toastService.message$.next({
        severity: 'info',
        summary: '',
        detail: 'No files selected.',
      })
    } else {
      this.fileService.downloadFiles(this.machineId, this.selectedFileIds).subscribe({
        next: response => {
          const blob = new Blob([response.body!]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'files.zip';
          console.log(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.selectedFileIds = [];
        },
        error: err => this.notifierService.handleRequestError(err)
      });
    }
  }
}
