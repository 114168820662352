import {Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {first, Observable} from 'rxjs';
import {NewResponse} from './models/newResponse.model';
import {DirectoryModel} from './models/directory.model';
import {FileModel} from './models/file.model';
import {Response} from './models/response.model';
import {ExtensionCategoryModel} from './models/extension-category.model';
import {FileCategoryModel} from './models/file-category.model';
import {ExtensionModel} from './models/extension.model';
import {TerminalFileCategoryModel} from './models/terminal-file-category.model';
import {SyncFileModel} from './models/sync-file.model';
import {FileOptionsModel} from './models/file-options.model';
import {SyncStatusCountModel} from './models/sync-status-count.model';


export const API_URL = new InjectionToken<string>('apiUrl');

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  private readonly urlDirectory: string;
  private readonly urlFile: string;
  private readonly urlHealthz: string;
  private readonly urlFileCategory: string;
  private readonly urlFileExtensionCategory: string;
  private readonly urlFileExtensions: string;
  private readonly urlFileSyncMetadata: string;
  private readonly urlTerminalFileCategory: string


  private readonly headersSkippingInterceptor: HttpHeaders;
  private readonly headers: HttpHeaders;
  private readonly optionsSkippingInterceptor: object;
  private readonly options: object;

  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {
    this.headersSkippingInterceptor = new HttpHeaders({
      'Content-Type': 'application/json',
      'skipInterceptor': 'true',
    });
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.optionsSkippingInterceptor = {
      headers: this.headersSkippingInterceptor,
      withCredentials: true
    };
    this.options = {
      headers: this.headers,
      withCredentials: true
    };

    this.urlHealthz = `${apiUrl}/orion-cc-file-transfer/healthz`;
    this.urlDirectory = `${apiUrl}/orion-cc-file-transfer/Directory`;
    this.urlFile = `${apiUrl}/orion-cc-file-transfer/File`;
    this.urlFileCategory = `${apiUrl}/orion-cc-file-transfer/FileCategory`;
    this.urlFileExtensionCategory = `${apiUrl}/orion-cc-file-transfer/FileExtensionCategory`;
    this.urlFileExtensions = `${apiUrl}/orion-cc-file-transfer/FileExtensions`;
    this.urlFileSyncMetadata = `${apiUrl}/orion-cc-file-transfer/FileSyncMetadata`;
    this.urlTerminalFileCategory = `${apiUrl}/orion-cc-file-transfer/TerminalFileCategory`;


  }

  // Health
  public getHealthz(): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlHealthz}`,this.optionsSkippingInterceptor).pipe(first());
  }

  // Directory

  public getDirectory(terminalId: string, page: number, size: number, directoryId?: string): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlDirectory}/${terminalId}?page=${page}&size=${size}&directoryId=${directoryId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getDirectoryById(directoryId: string, terminalId: string): Observable<NewResponse<DirectoryModel>> {
    return this.http.get<NewResponse<DirectoryModel>>(`${this.urlDirectory}/${directoryId}/${terminalId}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // File

  public updateFile(body: FileModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFile}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getFile(fileId: string, terminalId: string): Observable<Response<FileModel>> {
    return this.http.get<Response<FileModel>>(`${this.urlFile}/${terminalId}/${fileId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public downloadFile(terminalId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.urlFile}/${terminalId}/download/${fileId}`, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: this.headersSkippingInterceptor,
      params: new HttpParams(),
    }).pipe(first());
  }

  public getFileDownloadLink(terminalId: string, fileId: string): string {
    return `${this.urlFile}/${terminalId}/download/${fileId}`;
  }

  public getFileIdByPath(filePath: string, terminalId: string): Observable<Response<string>> {
    return this.http.get<Response<string>>(`${this.urlFile}/${terminalId}/path?filePath=${filePath}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public syncFile(body: SyncFileModel, terminalId: string): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFile}/${terminalId}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public fileOptions(
    terminalId: string,
    page: number,
    size: number,
    syncStatus: number,
    startDate?: any,
    endDate?: any
  ): Observable<NewResponse<FileOptionsModel[]>> {
    const params = new URLSearchParams({
      syncStatus: syncStatus.toString(),
      page: page.toString(),
      size: size.toString(),
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    }).toString();

    return this.http.get<NewResponse<FileOptionsModel[]>>(`${this.urlFile}/${terminalId}/options?${params}`, this.optionsSkippingInterceptor).pipe(first());
  }
  public downloadFiles(terminalId: string, fileIds: string[]): Observable<HttpResponse<Blob>> {
    return this.http.post(`${this.urlFile}/downloadSelected/${terminalId}`, fileIds, {
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
      headers: this.headersSkippingInterceptor,
      params: new HttpParams(),
    }).pipe(first());
  }

  public deleteFile(fileId: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFile}/${fileId}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getTerminalFiles (terminalId: string, page: number, size: number, directoryId: string): Observable<NewResponse<FileModel[]>> {
    return this.http.get<NewResponse<FileModel[]>>(`${this.urlFile}/terminal/${terminalId}?page=${page}&size=${size}&directoryId=${directoryId}`, this.optionsSkippingInterceptor).pipe(first());
  }


  public getSyncStatusCounts(terminalId: string): Observable<Response<SyncStatusCountModel[]>> {
    return this.http.get<Response<SyncStatusCountModel[]>>(`${this.urlFile}/${terminalId}/GetSyncStatusCounts`, this.optionsSkippingInterceptor).pipe(first());
  }

  // FileCategory

  public addFileCategory (body: FileCategoryModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFileCategory}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getFileCategories(page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileCategory}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getFileCategory(id: string) : Observable<Response<FileCategoryModel>> {
    return this.http.get<Response<FileCategoryModel>>(`${this.urlFileCategory}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateFileCategory(id: string, body: FileCategoryModel): Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFileCategory}/${id}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteFileCategory(id: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileCategory}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }


  // FileExtensionCategory

  public addFileExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlFileExtensionCategory}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>>{ // todo add id
    return this.http.put<Response<null>>(`${this.urlFileExtensionCategory}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteExtensionCategory (body: ExtensionCategoryModel): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileExtensionCategory}`,  {
      ...this.optionsSkippingInterceptor,
      body,
    }).pipe(first());
  }

  public getExtensionIds (fileCategoryId: string, page: number, size: number) : Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileExtensionCategory}/${fileCategoryId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }


  //FileExtensions

  public addExtension(body: ExtensionModel): Observable<Response<string>> {
    return this.http.post<Response<string>>(`${this.urlFileExtensions}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public getAllExtensions(page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlFileExtensions}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public getExtension(id: string) : Observable<Response<ExtensionModel>> {
    return this.http.get<Response<ExtensionModel>>(`${this.urlFileExtensions}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  public updateExtension (id: string, body: ExtensionModel) : Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlFileExtensions}/${id}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteExtension(id: string): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlFileExtensions}/${id}`, this.optionsSkippingInterceptor).pipe(first());
  }

  // //FileSyncMetadata
  //
  // public getSyncMetadata(fileSyncMetadataId: string) : Observable<NewResponse<{}>> {
  //   return this.http.get<NewResponse<{}>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}`, this.optionsSkippingInterceptor).pipe(first());
  // }
  //
  // public updatePriority (fileSyncMetadataId: string, body: {}) : Observable<Response<null>> {
  //   return this.http.put<Response<null>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}/Priority`, body, this.optionsSkippingInterceptor).pipe(first());
  // }
  //
  // public updateSyncStatus(fileSyncMetadataId: string, body: {}) : Observable<Response<null>> {
  //   return this.http.put<Response<null>>(`${this.urlFileSyncMetadata}/${fileSyncMetadataId}/status`, body, this.optionsSkippingInterceptor).pipe(first());
  // }
  //
  // public getStatuses (getAllFilesSyncStatus: string, terminalId: string, page: number, size: number): Observable<NewResponse<{}>> {
  //   return this.http.get<NewResponse<{}>>(`${this.urlFileSyncMetadata}/${getAllFilesSyncStatus}?terminalId=${terminalId}&page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  //
  // }

  //TerminalFileCategory


  public addTerminalFileCategory(body: TerminalFileCategoryModel): Observable<Response<null>> {
    return this.http.post<Response<null>>(`${this.urlTerminalFileCategory}`, body, this.optionsSkippingInterceptor).pipe(first());
  }
  public updateTerminalFileCategory (body: TerminalFileCategoryModel) : Observable<Response<null>> {
    return this.http.put<Response<null>>(`${this.urlTerminalFileCategory}`, body, this.optionsSkippingInterceptor).pipe(first());
  }

  public deleteTerminalFileCategory (body: TerminalFileCategoryModel): Observable<Response<null>> {
    return this.http.delete<Response<null>>(`${this.urlTerminalFileCategory}`,  {
      ...this.optionsSkippingInterceptor,
      body,
    }).pipe(first());
  }

  public getTerminalFileCategories(terminalId: string, page: number, size: number): Observable<NewResponse<string[]>> {
    return this.http.get<NewResponse<string[]>>(`${this.urlTerminalFileCategory}/${terminalId}?page=${page}&size=${size}`, this.optionsSkippingInterceptor).pipe(first());
  }

}
